<template>
  <div>
    <a-spin :spinning="loading" tip="Processing audio...">
      <h2>Upload Audio for Analysis</h2>

      <!-- Ant Design Vue's a-upload-dragger for drag-and-drop -->
      <a-upload-dragger
        :custom-request="handleUpload"
        accept=".flac"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">Click or drag FLAC file to this area to upload</p>
        <p class="ant-upload-hint">Only .flac files are accepted</p>
      </a-upload-dragger>

      <!-- Prompt Text Area -->
      <a-textarea
        v-model="promptText"
        placeholder="Enter analysis prompt"
        rows="4"
        style="margin-top: 10px; width: 100%;"
      />

      <!-- Submit Button (disabled until prompt and audio are provided) -->
      <a-button
        type="primary"
        :disabled="!audioFile || !promptText"
        @click="uploadAudio"
        style="margin-top: 10px;"
      >
        Submit for Analysis
      </a-button>
    </a-spin>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import { functions } from '@/services/firebase'

export default {
  data() {
    return {
      audioFile: null, // Holds the uploaded audio file
      promptText: '', // Holds the prompt text
      loading: false, // Spinner state
    }
  },
  methods: {
    // Validate before uploading (Only accept FLAC files)
    // beforeUpload(file) {
    //   const isFlac = file.type === 'audio/flac'
    //   if (!isFlac) {
    //     message.error('Only FLAC files are accepted!')
    //   }
    //   return isFlac || Upload.LIST_IGNORE
    // },

    // Handles the custom request to process file upload
    handleUpload({ file }) {
      this.audioFile = file
      this.uploadAudio() // Automatically start analysis after file is uploaded
    },

    // Uploads the audio file and performs the analysis
    async uploadAudio() {
      if (!this.audioFile || !this.promptText) {
        message.error('Please upload a FLAC audio file and provide a prompt.')
        return
      }

      this.loading = true // Show spinner
      const reader = new FileReader()
      reader.readAsDataURL(this.audioFile) // Convert audio to base64

      reader.onload = async () => {
        const base64Audio = reader.result.split(',')[1] // Extract base64 data

        try {
          const analyzeAudioFunction = functions.httpsCallable('analyze_audio_gcf')
          const response = await analyzeAudioFunction({
            audio: base64Audio, // Pass base64 encoded audio
            prompt: this.promptText, // Pass prompt
          })

          console.log('Analysis Result:', response.data)
          message.success('Audio analysis completed!')
        } catch (error) {
          console.error('Error analyzing audio:', error)
          message.error('Error analyzing audio!')
        } finally {
          this.loading = false // Hide spinner
        }
      }
    },
  },
}
</script>

<style>
/* Optional: Style adjustments for the upload area */
</style>
